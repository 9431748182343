const SAVE = 'SAVE'
const SAVED = 'SAVED'
const PUBLISH = 'PUBLISH'
const PUBLISHED = 'PUBLISHED'
const UPLOAD_COVER = 'UPLOAD_COVER'
const UPLOAD_IMAGE = 'UPLOAD_IMAGE'

const initialState = {
    saving: false,
    publishing: false,
    uploadingCover: false,
    uploadingImage: false
}

export const save = () => ({ type: SAVE })
export const saved = () => ({ type: SAVED })
export const publish = () => ({ type: PUBLISH })
export const published = () => ({ type: PUBLISHED })
export const uploadCover = () => ({ type: UPLOAD_COVER })
export const uploadImage = () => ({ type: UPLOAD_IMAGE })

export default function (state = initialState, action) {
    switch (action.type) {
        case SAVE:
            return Object.assign({}, state, {
                saving: true
            })
        case SAVED:
            return Object.assign({}, state, {
                saving: false,
                uploadingCover: false,
                uploadingImage: false
            })
        case PUBLISH:
            return Object.assign({}, state, {
                publishing: true
            })
        case PUBLISHED:
            return Object.assign({}, state, {
                publishing: false
            })
        case UPLOAD_COVER:
            return Object.assign({}, state, {
                uploadingCover: true
            })
        case UPLOAD_IMAGE:
            return Object.assign({}, state, {
                uploadingImage: true
            })
        default:
            return state
    }
}