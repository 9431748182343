import './common/common.css'
import React, { Component } from 'react'
import { Route, Switch, Redirect } from 'react-router-dom'
import Loadable from 'react-loadable'
import Loading from './common/components/loading'
import Sider from './sider/sider'
import { Layout, Drawer, Icon } from 'antd'

const isStudio = ['studio.heywalnut.com', 'localhost:3001'].includes(window.location.host)
const isCommunity = ['community.heywalnut.com', 'localhost:3002'].includes(window.location.host)

const Home = Loadable({ loader: () => import('./home/home'), loading: Loading })
const Settings = Loadable({ loader: () => import('./settings/settings'), loading: Loading })
const Create = Loadable({ loader: () => import('./create/create'), loading: Loading })
const Reports = Loadable({ loader: () => import('./reports/reports'), loading: Loading })
const Import = Loadable({ loader: () => import('./import/import'), loading: Loading })
const Seed = Loadable({ loader: () => import('./seed/seed'), loading: Loading })
const Quests = Loadable({ loader: () => import('./quests/quests'), loading: Loading })
const Paths = Loadable({ loader: () => import('./paths/paths'), loading: Loading })
const PathDraft = Loadable({ loader: () => import('./path-draft/path-draft'), loading: Loading })
const CourseDraft = Loadable({ loader: () => import('./course-draft/course-draft'), loading: Loading })
const LessonDraft = Loadable({ loader: () => import('./lesson-draft/lesson-draft'), loading: Loading })
const ContributeCourse = Loadable({ loader: () => import('./contribute/contribute-course'), loading: Loading })
const ContributeLesson = Loadable({ loader: () => import('./contribute/contribute-lesson'), loading: Loading })

class App extends Component {
    state = {
        drawer: false
    }

    toggle = () => this.setState({ drawer: !this.state.drawer })

    render() {
        return (
            <div className={`app ${isCommunity ? 'community' : ''}`}>
                <Layout>
                    <div className="hide-in-mobile">
                        <Sider />
                    </div>
                    <div className="show-in-mobile">
                        <div className="menu-button" onClick={() => this.toggle()} >
                            <Icon type="menu" />
                        </div>
                        <Drawer 
                            onClose={() => this.toggle()}
                            visible={this.state.drawer} placement="left">
                            <Sider close={() => this.toggle()} />
                        </Drawer>
                    </div>
                    <Layout className={`main ${isCommunity ? 'community' : ''}`}>
                        {isStudio &&
                            <Switch>
                                <Route exact path='/' component={Home} />
                                <Route exact path='/settings' component={Settings} />
                                <Route exact path='/create' component={Create} />
                                <Route exact path='/reports' component={Reports} />
                                <Route exact path='/import' component={Import} />
                                <Route exact path='/seed' component={Seed} />
                                <Route exact path='/quests' component={Quests} />
                                <Route exact path='/paths' component={Paths} />
                                <Route exact path='/path/:pathId' component={PathDraft} />
                                <Route exact path='/draft/:courseId' component={CourseDraft} />
                                <Route exact path='/draft/:courseId/:lessonId' component={LessonDraft} />
                                <Route exact path='/review/:authorId/:courseId' component={CourseDraft} />
                                <Route exact path='/review/:authorId/:courseId/:lessonId' component={LessonDraft} />   
                                <Redirect path="*" to="/" />
                            </Switch>
                        }
                        {isCommunity && 
                            <Switch>
                                <Route exact path='/' component={Home} />
                                <Route exact path='/settings' component={Settings} />  
                                <Route exact path='/contribute/:publicCourseId' component={ContributeCourse} />
                                <Route exact path='/contribute/:publicCourseId/:lessonId' component={ContributeLesson} />
                                <Redirect path="*" to="/" />
                            </Switch>
                        }
                    </Layout>
                </Layout>
            </div>
        )
    }
}

export default App
