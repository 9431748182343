import React from 'react'
import { Dropdown, Menu, Avatar } from 'antd'

const Profile = props => {
    return (
        <Dropdown placement="bottomRight" trigger={['click']} overlay={
            <Menu>
                <Menu.Item onClick={props.signout}>
                    Logout
                </Menu.Item>
            </Menu>}>
            <div className="profile-button">
                <Avatar size="large" icon="user" src={props.picture} />
            </div>
        </Dropdown>
    )
}

export default Profile