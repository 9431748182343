import './sider.css'
import React, { Component } from 'react'
import { connect } from 'react-redux'
import { Link, withRouter } from 'react-router-dom'
import { firestoreConnect } from 'react-redux-firebase'
import { signout } from '../auth/actions'
import Profile from './components/profile'
import { Layout, Menu, Badge } from 'antd'

const isAdmin = email => email === 'h@veronode.com' || email === 'harrison0723@gmail.com'

export class Sider extends Component {
    render() {
        const { close, signout, location, auth, currentUser, reports, saving } = this.props
        const isDraft = location.pathname.includes('/draft/')
        const authenticated = auth.isLoaded && !auth.isEmpty
        const email = auth.email

        return (
            <Layout.Sider theme="light" className="sider">
                <div className="header">   
                    {isDraft ?
                        <h2>Draft</h2>
                        :
                        <h2>Studio</h2>
                    }                 
                    {authenticated && <Profile 
                        picture={currentUser && currentUser.picture}
                        email={auth.email} 
                        signout={signout} 
                    />}
                </div>
                {isDraft && <p className="status"><Badge status={saving ? 'warning' : 'success'} />{saving ? 'Saving...' : 'All saved'}</p>}
                <Menu theme="light" mode="inline" selectedKeys={[location.pathname]}>
                    <Menu.Item key="/">
                        <Link to='/' onClick={close}>
                            Home
                        </Link>
                    </Menu.Item>
                    {isAdmin(email) &&
                        <Menu.Item key="/paths">
                            <Link to='/paths'>
                                Paths
                            </Link>
                        </Menu.Item>
                    }
                    {authenticated &&
                        <Menu.Item key="/create">
                            <Link to='/create' onClick={close}>
                                Create course
                            </Link>
                        </Menu.Item>
                    }
                    {authenticated &&
                        <Menu.Item key="/reports">
                            <Link to='/reports'>
                                Your reports {reports && !!reports.length && <Badge status="error" />}
                            </Link>
                        </Menu.Item>
                    }
                    {authenticated &&
                        <Menu.Item key="/settings">
                            <Link to='/settings'>
                                Your info
                            </Link>
                        </Menu.Item>
                    }
                </Menu>
            </Layout.Sider>
        )
    }
}

const mapState = state => ({
    auth: state.firebase.auth,
    currentUser: state.firestore.data.currentUser,
    reports: state.firestore.ordered.reports,
    saving: state.save.saving
})

const actions = { signout }

const query = props => {
    if (!props.auth.uid) return []
    else return [{
        collection: 'users',
        doc: props.auth.uid,
        storeAs: 'currentUser'
    }, {
        collection: 'users',
        doc: props.auth.uid,
        subcollections: [{
            collection: 'reports',
            orderBy: ['reportDate', 'desc']
        }],
        storeAs: 'reports'
    }]
} 

export default withRouter(connect(mapState, actions)(firestoreConnect(query)(Sider)))
