import firebase from 'firebase/app'
import 'firebase/firestore'
import 'firebase/storage'
import 'firebase/auth'
import secrets from './secrets'

const firebaseConfig = {
    apiKey: secrets.API_KEY,
    authDomain: secrets.AUTH_DOMAIN,
    databaseURL: secrets.DATABASE_URL,
    projectId: secrets.PROJECT_ID,
    storageBucket: secrets.STORAGE_BUCKET,
    messagingSenderId: secrets.MESSAGING_SENDER_ID
}

firebase.initializeApp(firebaseConfig)
const firestore = firebase.firestore()
const settings = { timestampsInSnapshots: true }
firestore.settings(settings)

export default firebase